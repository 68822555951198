export interface DemographicSegment {
  label: string;
  value: number;
}

export interface Segments {
  [key: string]: DemographicSegment;
}

export interface DictionaryTranslations {
  ageTitle: string;
  genderTitle: string;
  childrenTitle: string;
  incomeTitle: string;
  educationTitle: string;
  householdTitle: string;
  ageRange: string;
  ageUnder: string;
  ageAbove: string;
  female: string;
  male: string;
  unknown: string;
  haveChildren: string;
  noChildren: string;
  childrenBelowAverage: string;
  childrenAverage: string;
  childrenAboveAverage: string;
  underValue: string;
  upToHighSchool: string;
  someCollege: string;
  undergraduate: string;
  graduate: string;
  primarySecondary: string;
  upperSecondary: string;
  postSecondary: string;
  bachelors: string;
  primaryEducation: string;
  secondaryGeneral: string;
  secondaryVocational: string;
  tertiaryEducation: string;
  householdSingles: string;
  householdCouples: string;
  householdFamilies: string;
  upperFirstCharUnknown: string;
  householdDwellingsBuildings: string;
  householdResidence: string;
  householdNonResidentialBuild: string;
  ageTooltip: string;
  genderTooltip: string;
  childrenTooltip: string;
  incomeTooltip: string;
  educationTooltip: string;
  householdTypeTooltip: string;
  [key: string]: string;
}

export interface Translations {
  title: string;
  monthsBreakdownTitle: string;
  categoryLabel: string;
  unavailableDataLabel: string;
  rotateYourScreenBanner: string;
  placeholderTitle: string;
  placeholderDescription: string;
  unavailableTooltipText: string;
  stayTunedTooltipText: string;
  hintDemographicsChart: string;
  hintDemographicsByMonthChart: string;
}

export enum Ids {
  Age = 'age',
  Gender = 'gender',
  Children = 'children',
  Income = 'income',
  Education = 'education',
  Household = 'household'
}

export type DemographicCategories = Ids.Age | Ids.Gender | Ids.Children | Ids.Income | Ids.Education | Ids.Household;

export type DemographicDictionary = {
  [key in DemographicCategories]: DemographicCategory;
};

export interface DemographicCategory {
  label: string;
  hintText: string;
  color: string;
  compareLink: string;
  segments: Segments;
}

export interface FeatureRange {
  [propName: string]: string;
}
export interface Features {
  age: boolean;
  gender: boolean;
  children: boolean;
  income: boolean;
  education: boolean;
  household: boolean;
  ageRange?: FeatureRange | undefined;
  childrenRange?: FeatureRange | undefined;
  incomeRange?: FeatureRange | undefined;
  educationRange?: FeatureRange | undefined;
  householdRange?: FeatureRange | undefined;
  [propName: string]: boolean | FeatureRange | undefined;
}

export interface PeriodItem {
  period: string;
  value: string;
  isPeriodEmpty?: boolean;
  arePreviousPeriodsEmpty?: boolean;
}
