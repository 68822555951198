import { Moment } from 'moment';

export interface RewardsState {
  loading: boolean;
  crmClientId: number;
  emCampaignStatuses: any;
  giftChainId: number | null;
  isCoreConfirmModalOpen: boolean;
  isCoreSuccessModalOpen: boolean;
  isFreezeModalWithFutureActivationDateOpen: boolean;
  error: any;
  promos: Promos;
  promosSettings: PromosSettings;
  coreProgram?: FetchActiveCoreProgramResponse;
}

export interface WelcomePromoSettings {
  error: any;
  loading: boolean;
  locallyChanged?: boolean;
  settings: {
    crmCampaignName?: string;
    crmEventName?: string;
    includeIncentive: string | boolean;
    loyaltyStartDate: string | Moment;
    pointQuantity: number;
    receiptText: string;
    receiptMessaging: boolean;
    emailMessaging: boolean;
  };
}

export interface AlmostRewardPromoSettings {
  error: any;
  loading: boolean;
  locallyChanged?: boolean;
  settings: {
    crmCampaignName?: string;
    crmEventName?: string;
    pointsFromReward: number;
    emailMessaging: boolean;
    receiptMessaging: boolean;
    receiptText: string;
  };
}

export interface MissYouPromoSettings {
  error: any;
  loading: boolean;
  locallyChanged?: boolean;
  settings: {
    crmCampaignName?: string;
    crmEventName?: string;
    daysSinceLastVisit: number;
    loyaltyStartDate: string | Moment;
    emailMessaging: boolean;
  };
}

export interface AnniversaryPromoSettings {
  error: any;
  loading: boolean;
  locallyChanged?: boolean;
  settings: {
    crmCampaignName?: string;
    crmEventName?: string;
    includeIncentive: string | boolean;
    loyaltyStartDate: string | Moment;
    pointQuantity: number;
    emailMessaging: boolean;
  };
}

export interface BirthdayPromoSettings {
  error: any;
  loading: boolean;
  locallyChanged?: boolean;
  settings: {
    crmCampaignName?: string;
    crmEventName?: string;
    includeIncentive: string | boolean;
    loyaltyStartDate: string | Moment;
    pointQuantity: number;
    emailMessaging: boolean;
  };
}

export interface CorePromoSettings {
  error: any;
  loading: boolean;
  locallyChanged?: boolean;
  settings: {
    crmCampaign?: boolean;
    crmCampaignName?: string;
    crmEventName?: string;
    enabled?: boolean;
    id?: string;
    loyaltyStartDate: string | Moment | null;
    emailMessaging: boolean;
    receiptMessaging: boolean;
    receiptText: string;
  };
}

export interface Promos {
  loyalty_reward: PromoStatus | {};
  welcome: PromoStatus | {};
  almost_reward: PromoStatus | {};
  miss_you: PromoStatus | {};
  anniversary: PromoStatus | {};
  birthday: PromoStatus | {};
}

export interface PromosSettings {
  [key: string]:
    | CorePromoSettings
    | WelcomePromoSettings
    | AlmostRewardPromoSettings
    | MissYouPromoSettings
    | AnniversaryPromoSettings
    | BirthdayPromoSettings;
  loyalty_reward: CorePromoSettings;
  welcome: WelcomePromoSettings;
  almost_reward: AlmostRewardPromoSettings;
  miss_you: MissYouPromoSettings;
  anniversary: AnniversaryPromoSettings;
  birthday: BirthdayPromoSettings;
}

export interface PromosRequest {
  crmClientId: string;
  mid: string;
  businessId: string;
  alxVersion: string;
}

export interface PromosResponse {
  giftChainId: string;
  promos: {
    almost_reward: PromoStatus;
    anniversary: PromoStatus;
    birthday: PromoStatus;
    loyalty_reward: PromoStatus;
    miss_you: PromoStatus;
    welcome: PromoStatus;
    lastVersionCoreProgram?: PromoPendingStatus;
  };
}

export interface PromoStatus {
  id: string;
  enabled: boolean;
  crmCampaign: boolean;
  rewardAmount: number;
  crmCampaignName?: string;
  crmEventName?: string;
  loyaltyStartDate?: string;
  pointThreshhold?: number;
  receiptText?: string;
}

export interface PromoPendingStatus {
  activationDate: string;
  active: boolean;
  merchantNumber: string;
  partnerId: string;
  payload: PromoStatus;
  updateDate: string;
  version: number;
  _id: string;
}

export interface PromoSettingsResponseAction {
  type: string;
  settings: any;
  cancellationDate?: string;
}

export interface PromoSettingsRequestAction {
  type: string;
  promoId: string;
}

export interface PromoSettingsRequest {
  type: string;
  promoId: string;
  alxVersion: string;
}

export interface PromoSettingsCancel {
  type: string;
}

export interface PromoSettingsResponse {
  crmCampaignName: string;
  crmEventName: string;
  includeIncentive?: string;
  loyaltyStartDate?: string;
  pointQuantity?: number;
  receiptText?: string;
  rewardType?: string;
  daysSinceLastVisit?: number;
}

export interface PromoCancellationResponse {
  subscriptionId: string;
  active: boolean;
  partnerId: string;
  payload: any;
  cancellationDate: string;
  msk: string[];
  updatedAt: string;
}

export interface SavePromosRequestAction {
  formData: any;
  isDelayedAction?: boolean;
  isActivationDateInFuture?: boolean;
  signature?: {
    actionType: string;
    initials: string;
  };
  isCoreProgramPage?: boolean;
  isAutomationPage?: boolean;
}

export interface SavePromosRequest {
  crmClientId: string;
  giftChainId: string;
  mid: string;
  businessId: string;
  alxVersion: string;
  promos: any;
  signature?: {
    actionType: string;
    initials: string;
  };
  termsUrl?: string;
  privacyPolicyUrl?: string;
}

export interface MPLPayload {
  termsUrl: string;
  privacyPolicyUrl: string;
}
export interface UpdateStoreboardingPayload {
  merchantNumbers: string[];
  organizationName: string;
  displayName: string;
  privacyPolicyUrl?: string;
}

export interface SavePromosResponse {
  giftChainId: string;
  promos: {
    almost_reward: PromoStatus;
    anniversary: PromoStatus;
    birthday: PromoStatus;
    loyalty_reward: PromoStatus;
    miss_you: PromoStatus;
    welcome: PromoStatus;
  };
}

export interface CRMStatusResponse {
  crmClientId: number;
  emCampaignStatuses: {
    almost_reward: boolean;
    anniversary: boolean;
    birthday: boolean;
    loyalty_reward: boolean;
    miss_you: boolean;
    welcome: boolean;
  };
}

export interface CRMStatusResponseAction {
  id: number;
  statuses: {
    almost_reward: boolean;
    anniversary: boolean;
    birthday: boolean;
    loyalty_reward: boolean;
    miss_you: boolean;
    welcome: boolean;
  };
}

export interface CRMStatuses {
  [key: string]: boolean;
}

export interface RewardsTabStatus {
  path: string;
  notAvailable: boolean;
  hint: string;
}

// Loyalty

export enum LoyaltyMethodName {
  rewards = 'rewards',
  monthlySummary = 'monthlySummary',
  total = 'total'
}

export type RewardsLoyaltyData = LoyaltyMonthlySummaryResponse | LoyaltyRewardsResponse | LoyaltyTotalResponse;

export interface LoyaltyRequest {
  path: string;
  payload?: {};
  method?: string;
}

export interface LoyaltyMonthlySummaryResponseItem {
  amount: number;
  MoM: number;
  YoY: number;
}

export interface LoyaltyMonthlySummaryResponse {
  visits: LoyaltyMonthlySummaryResponseItem;
  sales: LoyaltyMonthlySummaryResponseItem;
  avgTicket: LoyaltyMonthlySummaryResponseItem;
}

export interface LoyaltyRewardsChartDataItem {
  startTime: string;
  count: number;
  rewardsValue: number;
  noData: boolean;
}

export interface LoyaltyRewardsResponse {
  total: {
    last30Days: {
      amount: number;
      rewardsValue: number;
    };
    last12Months: {
      amount: number;
      rewardsValue: number;
    };
  };
  chartData: {
    last30Days: LoyaltyRewardsChartDataItem[];
    last12Months: LoyaltyRewardsChartDataItem[];
  };
}

export interface LoyaltyTotalResponse {
  members: {
    amount: number;
  };
  rewards: {
    amount: number;
    rewardsValue: number;
  };
}

export interface UpdateLoyaltyCoreProgramRequest {
  termsUrl: string;
  privacyPolicyUrl: string;
  locations: any;
  includeContactsWithoutLocations: any;
  timezone: string;
}

export interface FetchActiveCoreProgramPayload {
  partnerId: string;
}

export interface FetchActiveCoreProgramResponse {
  discountId: string;
  discountDescription: string;
}
