import styled from 'styled-components';
import { connect } from 'react-redux';
import * as React from 'react';
import { footerHeight, deviceMedia } from 'appConstants';
import { AppState } from 'store/rootReducer';
import { FooterLink } from 'store/branding/types';
import { getActivePage } from 'store/navigation';
import {
  getFooterLogoAltText,
  getCopyright,
  getFooterLinks,
  getFooterLogo,
  getFooterLogoStyles
} from 'store/branding/selectors';
import { getLoyaltyCalculatorSideBar } from 'store/rewards/selectors';
import { googleAnalytics } from 'services/googleAnalytics';
import { isPdfModeEnabled } from 'store/pdf';
import { LinkedLogo } from 'shared-components/LinkedLogo';
import { MarginForCalculatorSideBar } from 'components/rewards/CoreProgram/calculator/CalculatorContainer';
import { localizationService } from 'services/localization';
import { getColor } from '../theme';
import { ComponentThemeProp } from '../theme/types';

/* eslint-disable no-confusing-arrow */
const FooterContainer = styled.footer<{ isLeftMenuHidden: boolean; marginTop?: number | false }>`
  background: ${(props: ComponentThemeProp): string => props.theme.baseColors.white};
  border-top: 1px solid ${props => getColor(props, 'mediumGrey')};
  padding: 10px ${({ isLeftMenuHidden }) => (isLeftMenuHidden ? 20 : 15)}px;
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}px` : null)};
  min-height: ${footerHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${deviceMedia.mobile} {
    padding: 10px ${({ isLeftMenuHidden }) => (isLeftMenuHidden ? 10 : 15)}px;
  }

  .bold-first-copyright-line {
    & p:first-child {
      font-weight: bold;
    }
  }
`;
/* eslint-enable no-confusing-arrow */

const LogoContainer = styled.div<{ additionalStyles: string }>`
  display: inline-block;

  .footer-logo-link {
    display: block;
  }

  .footer-logo-image {
    display: block;
    margin-bottom: 15px;
    width: 150px;
    height: auto;
  }

  ${props => props.additionalStyles};
`;

const CopyrightContainer = styled.div`
  margin-bottom: 20px;
  line-height: 1.6;

  p {
    margin: 0;
  }
`;

const LinksContainer = styled.div`
  .privacy-item {
    display: inline-block;
    margin: 0 10px;
    color: inherit;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media ${deviceMedia.mobile} {
      margin: 0 10px 0 0;
    }
  }

  a {
    cursor: pointer;
  }
`;

interface ConnectedProps {
  logo: string;
  logoAltText: string;
  logoStyles: string;
  copyright: string[];
  links: FooterLink[];
  isLeftMenuHidden: boolean;
  pdfMode: boolean;
  isLoyaltyCalculatorOpen?: boolean;
}

interface OwnProps {
  footerRef: React.RefObject<HTMLInputElement> | null;
}

const PrivacyTextItem = ({ pdfMode, link }: { pdfMode: boolean; link: FooterLink }): JSX.Element => {
  const onLinkClick = (): void => {
    googleAnalytics.events.portal.open(link.text);
  };

  return pdfMode ? (
    <span className="privacy-item">{link.text}</span>
  ) : (
    <a className="privacy-item" onClick={onLinkClick} rel="noopener noreferrer" target="_blank" href={link.url}>
      {link.text}
    </a>
  );
};

const FooterComponent = (props: ConnectedProps & OwnProps): JSX.Element => {
  const { isLeftMenuHidden, logoStyles, logo, logoAltText, links, copyright, pdfMode, isLoyaltyCalculatorOpen } = props;

  return (
    <FooterContainer
      id="app-footer"
      ref={props.footerRef}
      isLeftMenuHidden={isLeftMenuHidden}
      marginTop={isLoyaltyCalculatorOpen && MarginForCalculatorSideBar}
    >
      <div>
        <LogoContainer additionalStyles={logoStyles}>
          <LinkedLogo
            imageSrc={logo}
            imageAltText={logoAltText}
            onlyImg={pdfMode}
            linkClassName="footer-logo-link"
            imgClassName="footer-logo-image"
          />
        </LogoContainer>

        <CopyrightContainer
          data-test-id="footer-copyright"
          className={logoStyles.includes('.bold') ? 'bold-first-copyright-line' : ''}
        >
          {copyright.map((item, key) => (
            <p key={`${key}${item}`}>{item || <br />}</p>
          ))}
        </CopyrightContainer>

        <LinksContainer data-test-id="footer-links">
          {links.map(link => (
            <PrivacyTextItem key={link.url} pdfMode={pdfMode} link={link} />
          ))}
        </LinksContainer>
      </div>
    </FooterContainer>
  );
};

export const Footer = connect<ConnectedProps, void, OwnProps, AppState>(state => {
  const activePage = getActivePage(state);
  const locale = localizationService.getLocale();
  return {
    logo: getFooterLogo(state),
    logoAltText: getFooterLogoAltText(state),
    logoStyles: getFooterLogoStyles(state),
    copyright: getCopyright(state),
    links: getFooterLinks(state, locale),
    isLeftMenuHidden: activePage ? !!activePage.leftMenuHidden : false,
    pdfMode: isPdfModeEnabled(state),
    isLoyaltyCalculatorOpen: getLoyaltyCalculatorSideBar(state)
  };
})(FooterComponent);
