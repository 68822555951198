import { get } from 'lodash';

import { ApiGateway } from 'services/apiGateway';
import { variablesService } from 'services/variables';
import { localizationService } from '../localization';
import { GeocodeAddressComponent, GeocodeResponse, Coordinates } from './types';
import { formatString, composeQueryString } from '../utils';

export const BASE_URL = 'https://maps.googleapis.com/maps/api';
export const VERSION = '3.32';

export const DEFAULT_MAP_CENTER = { lat: 34.144308, lng: -118.803909 };
export const DEFAULT_ZOOM_LVL = 9;
export const DEFAULT_MARKER_SIZE = 25;

export const OK_RESULTS_STATUS_CODE = 'OK';

export const DEFAULT_MAP_OPTIONS = {
  disableDefaultUI: false,
  scrollwheel: false,
  draggable: true,
  clickable: true,
  disableDoubleClickZoom: false,
  gestureHandling: 'cooperative'
};

export const ERROR_MESSAGE = 'Unable to get geocode info for address: {0}';

export const URL_GEOCODE = 'geocode';
export const URL_TIMEZONE = 'timezone';
const COUNTRY_LEVEL = 'country';
const DEFAULT_COUNTRY = 'US';

export const getApiKey = (): string => variablesService.getItem('googleMapsKey') || '';

export const getCoordinates = (addressInfo: GeocodeResponse): Coordinates =>
  get(addressInfo, 'results[0].geometry.location', DEFAULT_MAP_CENTER);

export const getAddress = (addressInfo: GeocodeResponse, defaultValue: string): string =>
  get(addressInfo, 'results[0].formatted_address', defaultValue);

export const getCountryNameByPostalCode = (addressInfo: GeocodeResponse, postalCode: string): string => {
  let matchedResult = addressInfo.results.find(result => result.formatted_address.includes(postalCode));
  if (!matchedResult && addressInfo.results.length === 1) matchedResult = addressInfo.results[0];
  const addressComponents = get(matchedResult, 'address_components', []);
  const countryLevel = addressComponents.find((level: GeocodeAddressComponent) => level.types.includes(COUNTRY_LEVEL));

  return get(countryLevel, 'short_name', DEFAULT_COUNTRY);
};

export const getAddressInfo = async (address: string, country?: string) => {
  const params = {
    v: VERSION,
    key: getApiKey(),
    language: localizationService.getLanguage(),
    components: country ? `country:${country}` : '',
    address
  };
  const googleApiUrl = `${BASE_URL}/${URL_GEOCODE}/json?${composeQueryString(params)}`;
  const response = await ApiGateway.fetchGeocode(googleApiUrl);

  if (response.status === OK_RESULTS_STATUS_CODE) {
    return response;
  }
  throw new Error(formatString(ERROR_MESSAGE, address).join(''));
};

/**
 * receive origins and destination coordinates returns distance between them
 * @param lat1 {number}
 * @param lon1 {number}
 * @param lat2 {number}
 * @param lon2 {number}
 * @returns {number}
 */
export const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radLat1 = (Math.PI * lat1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radTheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  return dist;
};

export const convertMilesToKilometers = (miles: number): number => miles * 1.60934;
export const convertKilometersToMiles = (kilometers: number): number => kilometers / 1.60934;
