import { takeEvery, takeLatest, select, put, call } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { isEmpty, get, uniqWith, omit } from 'lodash';
import { getType } from 'typesafe-actions';
import { getMenuPages } from 'store/navigation';
import { isDanubeCompanyWithLoyaltyEnabled } from 'store/rewards/selectors';
import { getStaticTemplates } from 'components/EmailMarketing/automation/StaticTemplates';
import { getAccountName } from 'store/settings/selectors';
import { automationActions } from './actions';
import { ApiGateway } from '../../../services/apiGateway';
import { campaignEditorActions, convertServerTemplates, ServerTemplate } from '../campaignEditor';
import { getTemplates } from './selectors';
import { emailTemplateEditorActions, templateEditorSelector } from '../emailTemplateEditor';
import { getTemplateDataForSave } from '../utils';
import { getEmSettings, isDanubeCompany } from '../settings';
import { getPagePath } from '../../../services/navigation';
import { DEFAULT_AUTOMATION_CAMPAIGNS_STATUSES } from '../../../appConstants/emailMarketing';

function* fetchAutomationTemplates() {
  try {
    const templatesResponse = yield call(ApiGateway.fetchAutomationTemplates);
    const statusesResponse = yield call(ApiGateway.fetchAutomationStatuses);

    const staticTemplates = uniqWith(
      [...templatesResponse, ...getStaticTemplates()],
      (item, item2) => item.promoId === item2.promoId
    );

    const { promos } = statusesResponse;

    let templates = convertServerTemplates(staticTemplates).map(template => ({
      ...template,
      img: `data:image/png;base64, ${template.img}`
    }));

    if (!isEmpty(promos)) {
      templates = templates.map(template => {
        const { promoId = '' } = template;
        const promo = promos[promoId] || {};
        return { ...template, promo };
      });
    }

    yield put(automationActions.fetchAutomationTemplates.success(templates));
  } catch (e) {
    console.error('Error while loading automation templates: ', e);
    put(automationActions.fetchAutomationTemplates.failure());
  }
}

function* selectAutomationTemplate({ payload }: ReturnType<typeof campaignEditorActions.selectStaticTemplate>) {
  try {
    const templateId = payload;

    const staticTemplates = getStaticTemplates().filter(item => item.id === templateId);
    const response = !staticTemplates.length ? yield ApiGateway.getAutomationTemplate(templateId) : staticTemplates;
    const converted = get(convertServerTemplates(response), '[0]');

    if (!converted) {
      console.error('Error while getting template converted template is falsy');
      return;
    }

    yield put(emailTemplateEditorActions.setTemplate(converted));
    yield put(automationActions.selectAutomationTemplate.success());
  } catch (e) {
    console.error('Error while getting template', e);
    yield put(automationActions.selectAutomationTemplate.failure());
  }
}

function* getAutomationCoreStatus() {
  try {
    const response = yield ApiGateway.getAutomationCoreStatus();
    const { active } = response;
    yield put(automationActions.getAutomationCoreStatus.success(active));
  } catch (e) {
    console.error('Error while getting automation core status', e);
    yield put(automationActions.getAutomationCoreStatus.failure());
  }
}

function* getAutomationCampaignsStatus() {
  try {
    const { promos = DEFAULT_AUTOMATION_CAMPAIGNS_STATUSES } = yield ApiGateway.fetchAutomationStatuses();
    const store = yield select();
    yield put(
      automationActions.getAutomationCampaignsStatus.success(
        isDanubeCompanyWithLoyaltyEnabled(store) ? omit(promos, ['birthday']) : promos
      )
    );
  } catch (e) {
    console.error('Error while getting automation campaigns status', e);
    yield put(automationActions.getAutomationCampaignsStatus.failure());
  }
}

function* saveAutomationTemplate() {
  try {
    const state = yield select();
    const templateEditor = templateEditorSelector(state);
    const templates = getTemplates(state);
    const emSettings = getEmSettings(state);
    const isDanubeWithLoyalty = isDanubeCompanyWithLoyaltyEnabled(state);
    const isDanubeAccount = isDanubeCompany(state);
    const accountName = getAccountName(state);

    const { id = '' } = templateEditor;
    const selectedTemplate = templates.find((template: any) => template.id === id);

    if (!selectedTemplate) {
      console.error('Error while saving automation template, selectedTemplate is not found');
      return null;
    }

    const { name, promoId = '' } = selectedTemplate;
    const data = yield getTemplateDataForSave(
      templateEditor,
      emSettings,
      name,
      emailTemplateEditorActions.updateBox,
      isDanubeWithLoyalty,
      isDanubeAccount,
      accountName
    );

    const response = yield call(ApiGateway.saveAutomationTemplate, { id, promoId, data });
    yield put(automationActions.saveAutomationTemplate.success());
    yield fetchAutomationTemplates();
    return response;
  } catch (e) {
    console.error('Error while saving automation template', e);
    yield put(automationActions.saveAutomationTemplate.failure());
    return null;
  }
}

function* createCampaign() {
  try {
    const state = yield select();

    const editor = templateEditorSelector(state);
    const templates: ServerTemplate[] = getTemplates(state);

    const { id } = editor;
    const selectedTemplate = templates.find((template: ServerTemplate) => template.id === id);

    if (!selectedTemplate) {
      console.error('Error while creating campaign, template is falsy');
      return;
    }

    const { promoId } = selectedTemplate;

    const response = yield saveAutomationTemplate();

    if (response) {
      const { templateID: templateId } = response;
      yield call(ApiGateway.createAutomationCampaign, { promoId, templateId });

      yield put(automationActions.setAssignSuccessModalState(true));

      const pages = getMenuPages(state);
      yield put(push(getPagePath(pages, 'emailMarketing_root', 'emailMarketing_campaigns')));
    }
    yield put(automationActions.createCampaign.success());
  } catch (e) {
    console.error('Error while creating campaign', e);
    yield put(automationActions.createCampaign.failure());
  }
}

export function* automationSagas() {
  yield takeLatest(getType(automationActions.fetchAutomationTemplates.request), fetchAutomationTemplates);
  yield takeLatest(getType(automationActions.getAutomationCoreStatus.request), getAutomationCoreStatus);
  yield takeLatest(getType(automationActions.saveAutomationTemplate.request), saveAutomationTemplate);
  yield takeLatest(getType(automationActions.getAutomationCampaignsStatus.request), getAutomationCampaignsStatus);
  yield takeEvery(getType(automationActions.selectAutomationTemplate.request), selectAutomationTemplate);
  yield takeEvery(getType(automationActions.createCampaign.request), createCampaign);
}
