import { get } from 'lodash';
import { LocaleSpecification } from 'moment';
import { DEFAULT_RETURNING_FREQUENCY } from '../../appConstants';
import { AppState } from '../rootReducer';
import {
  DigestStatus,
  DistanceMetrics,
  LocationsList,
  Settings,
  UserLocation,
  ReturningFrequency,
  TemperatureMetrics,
  Config,
  MerchantPrograms,
  SettingsState
} from './types';
import { LayoutElement, Page, PagesList } from './layout';
import { getConfirmedTermsFromLocalStorage, AvailableTermsTypes } from '../terms';

export const getAccountName = (state: AppState): string => state.settings.accountName;
export const getSelectedLocations = (state: AppState): string[] => state.locationSelector.selectedLocations || [];

export const getSelectedLocationsInfo = (state: AppState) => {
  const selectedLocationsIds = getSelectedLocations(state);
  return state.settings.locations.filter(location => selectedLocationsIds.includes(location.merchant_sequence_key));
};

export const getLocations = (state: AppState) => state.settings.locations;

export const getSelectedMids = (state: AppState): string[] =>
  getSelectedLocationsInfo(state).map(({ merchant_number: merchantNumber }) => merchantNumber);

export const isOneLocationSelected = (state: AppState): boolean => getSelectedLocations(state).length === 1;

export const isSettingsLoaded = (store: AppState): boolean => !store.settings.loading;
export const getUserLocations = (store: AppState): LocationsList => store.settings.locations;
export const getLocationByMid = (store: AppState, mid?: string) =>
  store.settings.locations.find(el => el.merchant_number === mid);
export const getMerchantNumbersMap = (store: AppState) => {
  const merchantNumbersMap: { [key: string]: string } = {};
  store.settings.locations.forEach(location => {
    merchantNumbersMap[location.merchant_sequence_key] = location.merchant_number;
  });
  return merchantNumbersMap;
};
export const getLocationsMapByMid = (store: AppState) => {
  const locationMap: { [key: string]: UserLocation } = {};
  store.settings.locations.forEach(location => {
    locationMap[location.merchant_number] = location;
  });
  return locationMap;
};
export const isOneLocationLogged = (state: AppState): boolean => getUserLocations(state).length === 1;

export const getDigestStatus = (state: AppState) => {
  const { digestStatus } = state.settings;
  return digestStatus || {};
};

export const getLoyaltyPointsSetup = (state: AppState) => state.settings?.loyaltyPointsSetup;

export const getLocationsWithEnabledDigest = (state: AppState) => {
  const status = getDigestStatus(state);
  return Object.entries(status)
    .filter(([_, params]) => params.enabled)
    .map(([location]) => location);
};

export const getSettingValue = (state: AppState, itemKey: keyof Settings) => {
  const settingsArray = Object.values(state.settings.settings);
  return Array.isArray(settingsArray) && settingsArray.length > 0 ? settingsArray[0][itemKey] : null;
};

export const getDistanceMetrics = (state: AppState): DistanceMetrics => {
  const defaultDistanceMetrics = state.settings.config.defaultDistanceMetrics || 'kilometers';
  const selected = getSettingValue(state, 'distanceMetrics');
  return (selected as DistanceMetrics) || defaultDistanceMetrics;
};

export const getTemperatureMetrics = (state: AppState): TemperatureMetrics => {
  const defaultTemperatureMetrics = state.settings.config.defaultTemperatureMetrics || 'fahrenheit';
  const selected = getSettingValue(state, 'temperatureMetrics');
  return (selected as TemperatureMetrics) || defaultTemperatureMetrics;
};

export const isPendingForUpdateSettings = (state: AppState) => state.settings.pendingUpdate;

export const getSettingsLanguage = (state: AppState) => {
  const { defaultLocale } = state.settings.config;
  return getSettingValue(state, 'language') || defaultLocale || '';
};

export const getSettingsReturningFrequency = (state: AppState): ReturningFrequency =>
  (getSettingValue(state, 'returningFrequency') as ReturningFrequency) || DEFAULT_RETURNING_FREQUENCY;

const getLatestDigestByStatus = (digestStatusArray: DigestStatus[], status: boolean) =>
  digestStatusArray
    .filter(digestStatus => digestStatus.enabled === status && digestStatus.email && digestStatus.updatedAt)
    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

export const getDigestEmail = (state: AppState) => {
  const locations = getUserLocations(state);
  const { digestStatus } = state.settings;
  const digestStatusArray = Object.values(digestStatus);

  if (Array.isArray(digestStatusArray) && digestStatusArray.length > 0) {
    const [latestEnabledDigests] = getLatestDigestByStatus(digestStatusArray, true);
    if (latestEnabledDigests && latestEnabledDigests.email) return latestEnabledDigests.email;

    const [latestDisabledDigest] = getLatestDigestByStatus(digestStatusArray, false);
    if (latestDisabledDigest && latestDisabledDigest.email) return latestDisabledDigest.email;
  }

  if (Array.isArray(locations) && locations.length === 1) {
    return locations[0].email || '';
  }
  return '';
};

export const getAutoAlertStatus = ({ settings }: AppState) => settings.autoAlertsStatus;

export const getUpdatedAtTime = ({ settings }: AppState) => settings.updatedAt;
export const getMerchantPages = ({ settings }: AppState) => settings.merchantPages;
export const getMasterCardDataParams = (state: AppState) => {
  const { isDemo, partnerId, isCustomDemoData, cesPlansDemoEnv } = state.settings;
  return { isDemo, partnerId, isCustomDemoData, cesPlansDemoEnv };
};

function findFeatureInComponent(components: LayoutElement[], componentName: string, featureName?: string): boolean {
  for (let i = 0; i < components.length; i += 1) {
    const element = components[i];
    if (element.componentName === componentName) {
      return typeof featureName === 'string'
        ? get(element, `features.${featureName}`, false)
        : get(element, 'enabled', false);
    }
    if (Array.isArray(element.elements) && element.elements.length) {
      const enabledFeature = findFeatureInComponent(element.elements, componentName, featureName);
      if (enabledFeature) return enabledFeature;
    }
  }
  return false;
}

function findFeatureInPages(
  pages: PagesList,
  pageName: string,
  componentName?: string,
  featureName?: string,
  pageId?: string
): boolean {
  const pageFieldName = pageId ? 'pageId' : 'pageName';
  const pageFieldValue = pageId || pageName;
  for (let i = 0; i < pages.length; i += 1) {
    const page = pages[i];
    if (page[pageFieldName] === pageFieldValue) {
      if (typeof componentName !== 'string') return true;
      return findFeatureInComponent(page.elements, componentName, featureName);
    }
    if (Array.isArray(page.subPages) && page.subPages.length) {
      const res = findFeatureInPages(page.subPages, pageName, componentName, featureName, pageId);
      if (res) return res;
    }
  }
  return false;
}

export function isFeatureOrComponentEnabled(
  state: AppState,
  pageName: string,
  componentName?: string,
  featureName?: string,
  location?: string,
  excludedPageId?: string,
  pageId?: string
): boolean {
  const merchantPages = location && getMerchantPages(state)[location];
  const pages = merchantPages
    ? state.appMenu.pages
        .filter(page => merchantPages.includes(page.pageId))
        .filter(page => !excludedPageId || !new RegExp(excludedPageId).test(page.pageId))
    : state.appMenu.pages;
  return findFeatureInPages(pages, pageName, componentName, featureName, pageId);
}

export const isFeatureOrComponentEnabledInRootOrNestedElements = (
  state: AppState,
  pageId: string,
  component: string,
  feature: string
): boolean => {
  const page = state.appMenu.pages.find(menuPage => menuPage.pageId === pageId);

  if (!page) return false;

  const featureStatusInRootPage = isFeatureOrComponentEnabled(
    state,
    page.pageName,
    component,
    feature,
    undefined,
    undefined,
    pageId
  );
  const hasSubPages = Array.isArray(page.subPages) && page.subPages.length > 0;

  if (featureStatusInRootPage || !hasSubPages) return featureStatusInRootPage;

  const featureStatusInSubPages = page.subPages.map(subPage =>
    isFeatureOrComponentEnabled(state, subPage.pageName, component, feature)
  );

  return featureStatusInSubPages.some(status => status);
};

export const isTermsConfirmedForPageRedirect = (state: AppState, page: Page): boolean => {
  if (page.elements) {
    const termsModalElement = page.elements.find(element => element.componentName === 'TermsModal');
    if (!termsModalElement) return true;

    if (termsModalElement && termsModalElement.features) {
      const featureKeys = Object.keys(termsModalElement.features);
      const isSomeTermsConfirmationRequired = featureKeys.some(featureKey => termsModalElement.features[featureKey]);
      if (!isSomeTermsConfirmationRequired) return true;

      const locations = getUserLocations(state).map(location => location.merchant_sequence_key);
      const confirmedTerms = getConfirmedTermsFromLocalStorage(locations);

      const isSomeTermsConfirmed = featureKeys.some(
        featureKey =>
          termsModalElement.features[featureKey] && confirmedTerms.includes(featureKey as AvailableTermsTypes)
      );
      return isSomeTermsConfirmed;
    }

    return true;
  }

  return true;
};

export const isWeatherFeatureEnabled = (state: AppState): boolean =>
  isFeatureOrComponentEnabled(
    state,
    'customerIntelligence_root',
    'ExtraFeatures',
    'analyticsDashboard_weather',
    getSelectedLocations(state)[0]
  );

export const getConfig = ({ settings }: AppState): Config => settings.config;
export const getVersion = (state: AppState): string => {
  const { versions } = state.settings;
  const analyticsVersion = versions.find(service => service.name === 'application');
  return analyticsVersion ? analyticsVersion.version : '';
};
export const getUIVersion = (state: AppState): string => get(state, 'settings.uiVersion', '');

export const doesPagePathExist = (state: AppState, path: string): boolean =>
  state.appMenu.pages.some(page => page.path === path);

export const isComponentEnabledOnThePage = (page: Page | undefined, componentName: string): boolean => {
  if (page && (Array.isArray(page.elements) || Array.isArray(page.subPages))) {
    const elementExists = page.elements.some(element => element.componentName === componentName);

    return elementExists || page.subPages.some(subPage => isComponentEnabledOnThePage(subPage, componentName));
  }
  return false;
};

export const isEnterprise = (state: AppState): boolean => !!getConfig(state).isEnterprise;
export const isActionButtonsDisabled = (state: AppState): boolean => !!getConfig(state).isActionButtonsDisabled;
export const getMerchantPrograms = (state: AppState): MerchantPrograms => state.settings.merchantPrograms;
export const getMerchantProgramsByLocationsList = (state: AppState, locations: string[]): string[] => {
  const merchantPrograms = getMerchantPrograms(state);
  const uniqPrograms = new Set<string>();
  locations.forEach(location => merchantPrograms[location].forEach(programId => uniqPrograms.add(programId)));

  return Array.from(uniqPrograms);
};

export const isDemo = (state: AppState): boolean => state.settings.isDemo;
export const getGPUserId = (state: AppState): string | undefined => state.settings.gpUserId;
export const getLoginJWT = (state: AppState): string | undefined => state.settings.jwt;
export const getAppHubTrayItemsSelector = (state: AppState): SettingsState['appHubTrayItems'] =>
  state.settings.appHubTrayItems;

export const isAppHubTraySelector = (state: AppState): boolean => {
  const { appHubTrayItems } = state.settings;
  return !!(!appHubTrayItems.loading && Array.isArray(appHubTrayItems.data) && appHubTrayItems.data.length);
};
export const isAppHubPartnerSelector = (state: AppState): boolean => state.settings.config.isAppHubPartner;

export const getMomentSettingsSelector = (state: AppState, locale: string): LocaleSpecification | null | undefined => {
  const { localizationSettings } = state.settings?.config;
  return localizationSettings && localizationSettings[locale]?.momentSettings
    ? localizationSettings[locale].momentSettings
    : null;
};
export const getPartnerId = (state: AppState): string => state.settings.partnerId;

export const getIsDemo = (state: AppState): boolean => state.settings.isDemo || state.settings.cesPlansDemoEnv;
