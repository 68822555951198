import moment from 'moment';
import { get, set, merge } from 'lodash';
import {
  LOYALTY_CAMPAIGNS,
  LOYALTY_INCENTIVE_ACTIVE_TYPES,
  LOYALTY_REWARD,
  LOYALTY_REWARD_TYPES
} from '../../appConstants';
import { rewardsInitialState } from './store';
import { PromoPendingStatus, PromoStatus } from './types';

export const getLoyaltyApiPath = (method: string, customerId: number, queryParams?: [string, string][]): string =>
  `/loyalty/${customerId}/${method}?${new URLSearchParams(queryParams).toString()}`;

const isLoyaltyRewardEnabled = (promoData: any): boolean => !!promoData.enabled;

export const responseDataToFormFormat = (eventName: string, data: any, isApphubPartner = false) => {
  const settings = { ...data };

  if (!data.enabled) {
    return {
      crmCampaign: false,
      enabled: false,
      id: ''
    };
  }

  const settingsKeys = Object.keys(settings);
  const rewardAmountDivider = isApphubPartner ? 1 : 100;
  if (settingsKeys.includes('loyaltyStartDate')) settings.loyaltyStartDate = moment(settings.loyaltyStartDate);
  if (settingsKeys.includes('rewardAmount')) settings.rewardAmount /= rewardAmountDivider;
  if (settingsKeys.includes('crmEventName') && settingsKeys.includes('crmCampaignName')) {
    const enabled = settings.crmEventName === eventName && settings.crmCampaignName === LOYALTY_CAMPAIGNS[eventName];
    settings.emailMessaging = enabled;
  }
  if (settingsKeys.includes('receiptText')) {
    settings.receiptMessaging = settings.receiptText.length > 0;
    settings.receiptText = settings.receiptText.toString();
  }
  if (settingsKeys.includes('includeIncentive')) {
    settings.includeIncentive = !!settings.includeIncentive;
  }

  return settings;
};

export const getLoyaltyIncentiveTypes: any = (active: boolean, isDaube: boolean, appHubBroomField: boolean) => {
  if (active) {
    return isDaube || appHubBroomField
      ? LOYALTY_INCENTIVE_ACTIVE_TYPES.points
      : LOYALTY_INCENTIVE_ACTIVE_TYPES.purchases;
  }

  return '';
};

export const rewardsFormDataToRequestFormat = (
  eventName: any,
  data: any,
  promoId: string,
  isDanubeAccount: boolean,
  appHubBroomField: boolean
) => {
  const requestData = { ...data, id: data.id || promoId };
  const now = new Date();
  const requestKeys = Object.keys(requestData);
  if (requestKeys.includes('includeIncentive')) {
    if (!requestData.includeIncentive) requestData.pointQuantity = 0;
    requestData.rewardType = LOYALTY_REWARD_TYPES[requestData.includeIncentive];
    requestData.includeIncentive = getLoyaltyIncentiveTypes(
      requestData.includeIncentive,
      isDanubeAccount,
      appHubBroomField
    );
  }
  if (requestKeys.includes('emailMessaging')) {
    requestData.crmCampaignName = requestData.emailMessaging ? LOYALTY_CAMPAIGNS[eventName] : '';
    requestData.crmEventName = requestData.emailMessaging ? eventName : '';
    delete requestData.emailMessaging;
  }
  if (requestKeys.includes('rewardAmount')) requestData.rewardAmount *= 100;
  requestData.loyaltyStartDate = moment(requestData.loyaltyStartDate)
    .set({ hours: now.getHours(), minutes: now.getMinutes() })
    .toLocaleString();
  if (requestKeys.includes('receiptMessaging')) delete requestData.receiptMessaging;

  return requestData;
};

export const receivePromos = (state: any, action: any) => {
  const { payload } = action;
  const nextState: any = {};

  if (payload.crmClientId) nextState.crmClientId = payload.crmClientId;
  if (payload.giftChainId) nextState.giftChainId = payload.giftChainId;

  if (payload.promos) {
    set(nextState, 'promos', payload.promos);
    if (LOYALTY_REWARD in payload.promos) {
      const loyaltyRewardSettings = get(payload.promos, LOYALTY_REWARD);
      set(payload, `promos.${LOYALTY_REWARD}.enabled`, isLoyaltyRewardEnabled(loyaltyRewardSettings));
    }
    const promos: any = {};
    for (const [promoKey, promoData] of Object.entries<any>(payload.promos)) {
      const previousPromoId = get(state, `promos.${promoKey}.id`);
      if (previousPromoId && (previousPromoId !== promoData.id || promoKey === LOYALTY_REWARD)) {
        promos[promoKey] = {
          loading: false,
          settings: responseDataToFormFormat(promoKey, promoData)
        };
      }
    }

    if (Object.keys(promos).length) {
      Object.assign(nextState, { promosSettings: merge({}, state.promosSettings, promos) });
    }
  }
  return {
    ...state,
    loading: false,
    error: null,
    isPromosLoading: false,
    ...nextState
  };
};

export const requestPromoSettings = (state: any, action: any) => ({
  ...state,
  promosSettings: {
    ...state.promosSettings,
    [action.payload.type]: {
      ...state.promosSettings[action.payload.type],
      loading: true,
      error: null
    }
  }
});

export const receivePromoSettings = (state: any, action: any) => {
  const { type, settings, cancellationDate } = action.payload;
  const nextState = {
    ...state,
    promosSettings: {
      ...state.promosSettings,
      [type]: {
        ...state.promosSettings[type],
        loading: false,
        error: null,
        settings,
        cancellationDate: cancellationDate || null
      }
    }
  };
  if (type === LOYALTY_REWARD) {
    set(nextState, `promos.${LOYALTY_REWARD}.enabled`, isLoyaltyRewardEnabled(settings));
  }
  return nextState;
};

export const failRequestPromoSettings = (state: any, action: any) => {
  const { type, error } = action.payload;
  return {
    ...state,
    promosSettings: {
      ...state.promosSettings,
      [type]: {
        ...state.promosSettings[type],
        loading: false,
        error
      }
    }
  };
};

export const setDefaultPromoSettings = (state: any, action: any) => {
  const { type } = action.payload;
  return {
    ...state,
    promosSettings: {
      ...state.promosSettings,
      [type]: {
        // @ts-ignore
        ...rewardsInitialState.promosSettings[type],
        locallyChanged: true
      }
    }
  };
};

export const discardChangesPromoSettings = (state: any) => ({
  ...state,
  promosSettings: {
    ...rewardsInitialState.promosSettings
  }
});

export const mapLastVersionCoreProgram = (activeCoreProgram: PromoPendingStatus): PromoStatus => ({
  ...activeCoreProgram?.payload,
  loyaltyStartDate: activeCoreProgram?.activationDate,
  // [NV] This is needed due to difference in data formats
  rewardAmount: activeCoreProgram?.payload?.rewardAmount / 100
});
