import { createGlobalStyle } from 'styled-components';
import { TIPPY_CLASSNAME } from 'appConstants';
import { getColor } from './theme';

export const AceGlobalStyles = createGlobalStyle`
  body {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: ${props => getColor(props, 'darkGrey')};

    .ReactModal__Overlay {
      overflow-y: auto;
    }

    #main-container {
      position: relative;
      .ReactModal__Overlay.main-container {
        position: absolute !important;
        background-color: rgba(0,0,0,0.5) !important;
        z-index: 0 !important;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    .recharts-wrapper {
      overflow: hidden;
    }

    .${TIPPY_CLASSNAME}{
      color: inherit !important;
      background: #fff !important;
      font-size: inherit;
      line-height: inherit;
      border-radius: 0;

      .tippy-content{
        padding: 0;
      }
    }

    /* ie11 hack*/
    @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
      [data-tippy-root] {
        position: fixed !important;
      }
    }
  }
`;
