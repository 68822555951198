import { AppState } from '../../rootReducer';
import { DANUBE_ACCOUNTS, GP_POS_ACCOUNT } from '../../../appConstants';

export const getCountriesData = (state: AppState) => state.emailMarketing.settings.locationData;

export const getPublicSitesJWT = (state: AppState) => ({
  activeVersionJWT: state.emailMarketing.settings.activeVersionJWT,
  futureVersionJWT: state.emailMarketing.settings.futureVersionJWT
});
export const getConfigName = (state: AppState) => state.settings.config.name;
export const getDefaultMapCoords = (state: AppState) => state.settings.config.defaultMapCoords;
export const getAcePublicSiteDomain = (state: AppState) => state.settings.config.acePublicSitesDomain;
export const getEmSettings = (state: AppState) => state.emailMarketing.settings || {};
export const getEmCustomerSettings = (state: AppState) => state.emailMarketing.settings.customer;
export const isEmSettingsLoading = (state: AppState) => state.emailMarketing.settings.loading;
export const getGoToRewardsModalState = (state: AppState) => state.emailMarketing.settings.showGoToRewardsModal;
export const getEmCustomerId = (state: AppState) =>
  state.emailMarketing.settings.customer ? state.emailMarketing.settings.customer.customerId : -1;

export const isDanubeCompany = (state: AppState) => {
  const configName = getConfigName(state);
  return (configName && DANUBE_ACCOUNTS.includes(configName)) || false;
};

export const isAppHubBroomField = (state: AppState) => {
  const configName = getConfigName(state);
  return (configName && GP_POS_ACCOUNT === configName) || false;
};
